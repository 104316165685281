import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SocialIcon } from "react-social-icons";
import logo from "../../../Assets/Images/logo.png";
import Stage from "../../../Assets/svg/stagecroped.svg";
import Cloud from "../../../Assets/svg/cloudGroupBlack.svg";
import CloudGroup from "../../../Assets/svg/cloudGroupSVG.svg";
import CreatedBy from "../../../Assets/svg/createdBySVG.svg";
import classes from "./styles.module.css";
import { useNavigate, useNavigation } from "react-router-dom";
import footer1 from './footer1.json'
import footer2 from './footer2.json'
import { useTranslation } from "react-i18next";

const Footer = () => {

  const nav = useNavigate()
  const { t } = useTranslation()

  return (
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      {/* Last Part */}
      <Box
        sx={{
          backgroundColor: "#df0c62",
          padding: "32px 0 8px 0",

        }}
      >
        <Box width="100%" sx={{ backgroundColor: "#fff0d7" }}>
          <Container
            maxWidth="lg"
            sx={{ position: "relative", overflow: "hidden" }}
          >
            <Stack display="flex" direction="row" spacing={4} padding="32px 0">
              <Box flex={1}>
                <Stack
                  display="flex"
                  direction="column"
                  spacinng={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="#df0c62"
                    sx={{ fontSize: { xs: "0.7rem", md: "1rem" },marginBottom:'10px',borderBottom: "2px solid #feb659", }}
                  >
                    {t('site_map')}
                  </Typography>
                  {
                    footer1.map((f,ind) => (
                      <Typography
                      key={ind}
                        color="#df0c62"
                        textAlign="center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {if(f.to.includes('tel') || f.to.includes('mailto')) window.location.href = f.to; else nav(f.to);}}
                        sx={{ fontSize: { xs: "0.35rem", md: "1rem" },marginBottom:'2px' }}
                      >
                        {t(f.title)}
                      </Typography>
                    ))
                  }
                </Stack>
              </Box>
              <Box flex={2} zIndex={10}>
                <Stack
                  display="flex"
                  direction="column"
                  spacinng={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="#df0c62"
                    sx={{ fontSize: { xs: "0.7rem", md: "1rem" },marginBottom:'10px',borderBottom: "2px solid #feb659", }}
                  >
                    {t('legal_area')}
                  </Typography>
                  {
                    footer2.map((f,ind) => (
                      <Typography
                      key={ind}
                        color="#df0c62"
                        textAlign="center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {if(f.to.includes('tel') || f.to.includes('mailto')) window.location.href = f.to; else nav(f.to);}}
                        sx={{ fontSize: { xs: "0.35rem", md: "1rem" },marginBottom:'2px' }}
                      >
                        {t(f.title)}
                      </Typography>
                    ))
                  }
                </Stack>
              </Box>
              <Box flex={1} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Stack
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  spacing={2}
                >
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <img src={logo} alt="logo" width="95%" />
                  </Box>
                  <Box sx={{ display: { xs: "block", md: "none" } }}>
                    <img src={logo} alt="logo" width="50%" />
                  </Box>
                  <Stack display="flex" direction="row" spacing={1}>
                    <SocialIcon
                      bgColor="black"
                      url="https://www.facebook.com/share/1WecH383gS/"
                      style={{ width: '40px', height: '40px' }}
                    />
                    <SocialIcon
                      bgColor="black"
                      url="https://x.com/GuvenKeser24"
                      style={{ width: '40px', height: '40px' }}
                      network="twitter"
                    />
                    <SocialIcon
                      bgColor="black"
                      url="https://www.instagram.com/eticketal"
                      style={{ width: '40px', height: '40px' }}
                    />
                    <SocialIcon
                      bgColor="black"
                      url="https://youtube.com/@viyana2009"
                      style={{ width: '40px', height: '40px' }}
                    />
                  </Stack>
                  <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img src={Stage} alt="logo" height="150px" style={{position:'relative',bottom:'-35px'}} />
                  </Box>
                </Stack>
              </Box>
            </Stack>
            {/*<Box>
              <Stage
                style={{
                  fill: "#df0c62",
                  position: "absolute",
                  width: "100%",
                  height: "125%",
                  top: "15%",
                  right: "-35%",
                }}
              />
            </Box>*/}
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center'

          }}
        >
          <a target="_blank" href="https://clouddijital.com.tr">
            <img src={CreatedBy} alt="company logo" height="30px" width="auto" />
          </a>

          <a target="_blank" href="https://cloudbilisim.com.tr">
            <img src={CloudGroup} alt="company logo" height="20px" width="auto" />
          </a>

        </Box>
      </Container>
      {/* End Of Last Part */}
    </Box>
  );
};

export default Footer;
