import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SocialIcon } from "react-social-icons";
import { ReactComponent as Stage } from "../../../../Assets/svg/stage.svg";
import { ReactComponent as Cloud } from "../../../../Assets/svg/cloudGroupBlack.svg";
import CloudGroup from "../../../../Assets/svg/cloudGroupSVG.svg";
import CreatedBy from "../../../../Assets/svg/createdBySVG.svg";
import logo from "../../../../Assets/Images/logo.png";
import classes from "./styles.module.css";
import footer1 from "../footer1.json";
import footer2 from "../footer2.json";
import { useNavigate } from "react-router-dom";
import stage from "../../../../Assets/svg/stagecroped.svg";
import cloud from "../../../../Assets/svg/cloudGroupBlack.svg";
import cloud2 from "../../../../Assets/svg/cloud created dark 2.svg";

import { useTranslation } from "react-i18next";

const FooterMobile = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  return (
    <Stack
      display="flex"
      direction="column"
      spacing={0.5}
      sx={{ display: { xs: "flex", sm: "none" }, marginTop: "0!important" }}
    >
      <Box className={classes.endOfPage}>
        <Box className={classes.endOfPageColumn}>
          <Container maxWidth="xs">
            <Stack
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              pt={"8px"}
            >
              <Box
                sx={{
                  borderBottom: "2px solid #feb659",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5" fontWeight="bold" color="#df0c62">
                  {t("site_map")}
                </Typography>
              </Box>
              {footer1.map((f, ind) => (
                <Typography
                  key={ind}
                  variant="h6"
                  textAlign="center"
                  color="#df0c62"
                  onClick={() => {
                    if (f.to.includes("tel") || f.to.includes("mailto"))
                      window.location.href = f.to;
                    else nav(f.to);
                  }}
                >
                  {t(f.title)}
                </Typography>
              ))}
              <Box
                className="mt-5"
                sx={{
                  borderBottom: "2px solid #feb659",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5" fontWeight="bold" color="#df0c62">
                  {t("legal_area")}
                </Typography>
              </Box>
              {footer2.map((f, ind) => (
                <Typography
                  key={ind}
                  variant="h6"
                  textAlign="center"
                  color="#df0c62"
                  onClick={() => {
                    if (f.to.includes("tel") || f.to.includes("mailto"))
                      window.location.href = f.to;
                    else nav(f.to);
                  }}
                >
                  {t(f.title)}
                </Typography>
              ))}
              <Box display="flex" justifyContent="center" padding="5% 0">
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "80%" }}
                  className={classes.logo}
                />
              </Box>
              {/* Social Media Icons */}
              <Stack
                display="flex"
                direction="row"
                justifyContent="center"
                spacing={1}
                padding="5% 0"
              >
                <SocialIcon
                  bgColor="black"
                  url="https://www.facebook.com/share/1WecH383gS/"
                  style={{ width: "40px", height: "40px" }}
                />
                <SocialIcon
                  bgColor="black"
                  url="https://x.com/GuvenKeser24"
                  style={{ width: "40px", height: "40px" }}
                  network="twitter"
                />
                <SocialIcon
                  bgColor="black"
                  url="https://www.instagram.com/eticketal"
                  style={{ width: "40px", height: "40px" }}
                />
                <SocialIcon
                  bgColor="black"
                  url="https://youtube.com/@viyana2009"
                  style={{ width: "40px", height: "40px" }}
                />
              </Stack>
              <div
                style={{
                  height: "fit-content",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <a target="_blank" href="https://clouddijital.com.tr">
                  <img
                    src={CreatedBy}
                    alt="company logo"
                    height="40px"
                    width="auto"
                  />
                </a>

                <a target="_blank" href="https://cloudbilisim.com.tr">
                  <img
                    src={CloudGroup}
                    alt="company logo"
                    height="20px"
                    width="auto"
                  />
                </a>
              </div>
              {/* End of Social Icons */}

              <div
                className="text-center d-flex align-items-end mt-4"
                style={{ height: "220px", width: "100%" }}
              >
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={stage}
                  alt="stage"
                />
              </div>

              {/* <Stage className={classes.stage} /> */}
            </Stack>
          </Container>
          <div
            className="w-100"
            style={{ height: "50px", backgroundColor: "#fff" }}
          ></div>
        </Box>
      </Box>
      {/* <div className="container">
        <img src={cloud} alt="company logo" width="auto" style={{ marginBottom: '50px' }} />
      </div> */}
    </Stack>
  );
};

export default FooterMobile;
