import { Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import { SocialIcon } from "react-social-icons";
import logo from "../../../../Assets/Images/logo.png";
import stage from "../../../../Assets/svg/stage.svg";
import cloud from "../../../../Assets/svg/cloudGroupBlack.svg";
import classes from "./styles.module.css";
import footer1 from "../footer1.json";
import footer2 from "../footer2.json";
import { useNavigate } from "react-router-dom";
import CloudGroup from "../../../../Assets/svg/cloudGroupSVG.svg";
import CreatedBy from "../../../../Assets/svg/createdBySVG.svg";
import { useTranslation } from "react-i18next";

const FooterTablet = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#df0c62",
          padding: "32px 0 8px 0",
          marginTop: "0!important",
          overflow: "hidden",
        }}
      >
        <Box sx={{ backgroundColor: "#fff0d7", padding: "32px 0" }}>
          <Container maxWidth="md" sx={{ position: "relative" }}>
            <Stack
              display="flex"
              direction="row"
              spacing={2}
              sx={{ marginTop: "0!important" }}
            >
              {/* Left Column */}
              <Box flex={1}>
                <Stack display="flex" direction="column" alignItems="center">
                  <Typography
                    sx={{
                      marginBottom: "30px",
                      borderBottom: "2px solid #feb659",
                    }}
                    variant="h5"
                    fontWeight="bold"
                    color="#df0c62"
                  >
                    {t("site_map")}
                  </Typography>
                  {footer1.map((f, ind) => (
                    <Typography
                      key={ind}
                      sx={{ fontSize: { xs: "1rem", md: "1rem" } }}
                      textAlign="center"
                      color="#df0c62"
                      onClick={() => {
                        if (f.to.includes("tel") || f.to.includes("mailto"))
                          window.location.href = f.to;
                        else nav(f.to);
                      }}
                    >
                      {t(f.title)}
                    </Typography>
                  ))}
                </Stack>
              </Box>
              <Box flex={1}>
                <Stack display="flex" direction="column" alignItems="center">
                  <Typography
                    sx={{
                      marginBottom: "30px",
                      borderBottom: "2px solid #feb659",
                    }}
                    variant="h5"
                    fontWeight="bold"
                    color="#df0c62"
                  >
                    {t("legal_area")}
                  </Typography>
                  {footer2.map((f, ind) => (
                    <Typography
                      key={ind}
                      sx={{ fontSize: { xs: "1rem", md: "1rem" } }}
                      textAlign="center"
                      color="#df0c62"
                      onClick={() => {
                        if (f.to.includes("tel") || f.to.includes("mailto"))
                          window.location.href = f.to;
                        else nav(f.to);
                      }}
                    >
                      {t(f.title)}
                    </Typography>
                  ))}
                </Stack>
              </Box>
            </Stack>
            <Box flex={1}>
              <Stack display="flex" direction="column" spacing={1}>
                <Box className="text-center" m={"10% 0 5% 0"}>
                  <img src={logo} alt="logo" width="40%" />
                </Box>
                {/* Sosyal medya ikonlarının z-index'i artırıldı ve position relative eklendi */}
                <Stack
                  display="flex"
                  direction="row"
                  spacing={1}
                  sx={{ 
                    justifyContent: "center", 
                    position: "relative", 
                    zIndex: 5 
                  }}
                >
                  <SocialIcon
                    bgColor="black"
                    url="https://www.facebook.com/share/1WecH383gS/"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  />
                  <SocialIcon
                    bgColor="black"
                    url="https://x.com/GuvenKeser24"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                    network="twitter"
                  />
                  <SocialIcon
                    bgColor="black"
                    url="https://www.instagram.com/eticketal"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  />
                  <SocialIcon
                    bgColor="black"
                    url="https://youtube.com/@viyana2009"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  />
                </Stack>

                {/* Stage görseli için position relative ve z-index düşürüldü */}
                <div className="text-center" style={{ 
                  height: "150px", 
                  position: "relative", 
                  zIndex: 1 
                }}>
                  <img
                    src={stage}
                    alt="stage"
                    className={classes.bottomStage}
                    style={{ 
                      height: "400px", 
                      position: "relative", 
                      top: "0",
                      pointerEvents: "none" // Stage görseline tıklanabilirlik engellendi
                    }}
                  />
                </div>
              </Stack>
            </Box>
          </Container>
        </Box>
      </Box>
      {/* Cloud Sticker */}
      <Container maxWidth="md">
        <Box
          sx={{
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a target="_blank" href="https://clouddijital.com.tr">
            <img
              src={CreatedBy}
              alt="company logo"
              height="30px"
              width="auto"
            />
          </a>

          <a target="_blank" href="https://cloudbilisim.com.tr">
            <img
              src={CloudGroup}
              alt="company logo"
              height="20px"
              width="auto"
            />
          </a>
        </Box>
      </Container>
    </>
  );
};

export default FooterTablet;